import axios from "axios";

// const baseURL = process.env.REACT_APP_API_BASE_URL;

const api = axios.create({
  // baseURL: 'http://192.168.0.190:8009', 
  baseURL: 'https://atacadobelica.com.br/api_deploy/', 
  headers: {
      'Content-Type': 'application/json',
  },
});

api.interceptors.request.use((config) => {
  config.url += '?company=atacado';

  config.headers['Authorization'] = `Bearer ${localStorage.getItem('TOKEN')}`;

  return config;
});

export default api;